const registerDevice = (user: Object) => {
  const { $beamsClient } = useNuxtApp();

  $beamsClient
    .start()
    .then(() => {
      $beamsClient.addDeviceInterest(`role-${user.role}`);
    })
    .catch(console.error);
};

const unregisterDevice = () => {
  const { $beamsClient } = useNuxtApp();

  $beamsClient
    .start()
    .then(() => {
      $beamsClient.clearDeviceInterests();
    })
    .catch(console.error);
};

export { registerDevice, unregisterDevice };
